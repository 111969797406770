<template>
  <div class="bg-white">
    <section class="max-w-6xl mx-auto px-4 pb-16 relative z-10 bg-white">
      <h2 class="text-brandGrey uppercase text-center mb-6 text-3xl font-bold">
        Bet of the day
      </h2>

      <h3 class="text-center block mb-4 -mt-4 text-base text-brandGrey">
        {{ match.date }}
      </h3>

      <div class="flex items-center justify-center space-x-10">
        <Card :match="match" :botd="true" class="md:w-1/3 w-full" />
      </div>
    </section>
  </div>
</template>

<script>
import Card from "../components/Card";

export default {
  name: "BetOfTheDay",
  props: ["match"],
  components: {
    Card,
  },
};
</script>
